body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.badge {
  vertical-align : inherit;
  font-size : 24px;
}


.glyphicon {
  cursor: pointer
}

.datePicker{
  max-width: 110px;
  text-align: center;
}

.marginRight {
  margin-right: 12px !important;
}

.marginLeft {
  margin-left: 18px !important;
}

.marginBottom {
  margin-bottom: 10px !important;
}
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.panel {
  background-color:floralwhite;
  border: gainsboro 1px solid;
}

.panel .panel-heading {
  cursor: pointer;
} 
.panel .panel-collapse {
    overflow: hidden;
    -webkit-transition: height 0.3s ease-out;
    transition: height 0.3s ease-out;  
}
.panel  .panel-body {
    border: none !important;
}
.panel h2 {
  margin-top: 5px !important; 
  text-transform: capitalize
}


.options-margin-top {
  margin-top: 10px;
  cursor: pointer;
}

.root {
  flexGrow: 1,
}

.grow {
  flexGrow: 1,
}

.container {
  text-align: center;
  padding: 15px 0px 15px 0px;
  margin: 10px auto;
  width: 98% !important;
  padding-top: 50px
}

.sub-menu {
  color: rgba(0, 0, 0, 0.67);
  padding-left: 16px;
  padding-top: 10px;
}
.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }
}


table { overflow: visible !important; }
thead { display: table-header-group }
tfoot { display: table-row-group }
tr { page-break-inside: avoid }
.NotFound {
    padding-top: 100px;
    text-align: center;
}
